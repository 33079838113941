/* ***********************************************************
 * A short word on how to use this automagically generated file.
 * We're often asked in the ignite gitter channel how to connect
 * to a to a third party api, so we thought we'd demonstrate - but
 * you should know you can use sagas for other flow control too.
 *
 * Other points:
 *  - You'll need to add this saga to sagas/index.js
 *  - This MasukKendaraan uses the api declared in sagas/index.js, so
 *    you'll need to define a constant in that file.
 *************************************************************/

import { call, put, select } from "redux-saga/effects";
import MasukKendaraanActions from "../reducers/MasukKendaraanRedux";
// import { MasukKendaraanSelectors } from '../Redux/MasukKendaraanRedux'
import { CheckConnectionSelectors } from "../reducers/CheckConnectionRedux";
import getModalAlert from "../../components/ModalAlert";

export function* getTiketMasukByBarcodeRequest(api, action) {
  const { data } = action;

  // get current data from Store
  const currentConnection = yield select(
    CheckConnectionSelectors.getCurrentData
  );
  if (currentConnection === false) {
    getModalAlert(
      "Anda sedang offline. Silahkan cek koneksi internet atau input manual"
    );
    return yield put(MasukKendaraanActions.getTiketMasukByBarcodeFailure());
  }
  // make the call to the api

  const response = yield call(api.getTiketMasukByBarcode, data);
  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log("MasukKendaraan response.data", response.data);
    yield put(
      MasukKendaraanActions.getTiketMasukByBarcodeSuccess(response.data.data)
    );
  } else {
    yield put(MasukKendaraanActions.getTiketMasukByBarcodeFailure());
  }
}
