import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  saveTipeKendaraan: ["tipeKendaraan"],
  saveTarif: ["tarif"],
  saveJenisPembayaran: ["jenisPembayaran"],
  saveOrganization: ["organization"],
  saveGate: ["gates"],
  saveMember: ["member"],
  saveBrandKendaraan: ["brandKendaraan"],
  saveTenant: ["tenant"],
  saveKeluarKendaraan: ["keluarKendaraan"],
});

export const LocalTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  tipeKendaraan: [],
  tarif: [],
  jenisPembayaran: [],
  organization: [],
  gates: [],
  member: [],
  brandKendaraan: [],
  tenant: [],
  // keluarKendaraan: [],
});

/* ------------- Selectors ------------- */

export const LocalSelectors = {
  getData: (state) => state.data,
  // getDataKeluarKendaraan: (state) => state.local.keluarKendaraan,
};

/* ------------- Reducers ------------- */

export const savetipeKendaraan = (state, action) => {
  const { tipeKendaraan } = action;
  return state.merge({ tipeKendaraan: tipeKendaraan ? tipeKendaraan : [] });
};

export const savetarif = (state, action) => {
  const { tarif } = action;
  return state.merge({ tarif: tarif ? tarif : [] });
};

export const savejenispembayaran = (state, action) => {
  const { jenisPembayaran } = action;
  return state.merge({
    jenisPembayaran: jenisPembayaran ? jenisPembayaran : [],
  });
};

export const saveorganization = (state, action) => {
  const { organization } = action;
  return state.merge({ organization: organization ? organization : [] });
};

export const savegate = (state, action) => {
  const { gates } = action;
  return state.merge({ gates: gates ? gates : [] });
};

export const savemember = (state, action) => {
  const { member } = action;
  return state.merge({ member: member ? member : [] });
};

export const savebrand = (state, action) => {
  const { brandKendaraan } = action;
  return state.merge({ brandKendaraan: brandKendaraan ? brandKendaraan : [] });
};

export const savetenant = (state, action) => {
  const { tenant } = action;
  return state.merge({ tenant: tenant ? tenant : [] });
};

// export const savekeluarkendaraan = (state, action) => {
//   const { keluarKendaraan } = action;

//   return state.merge({
//     keluarKendaraan: keluarKendaraan ? keluarKendaraan : [],
//   });
// };
/* ------------- Hookup /Binding Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SAVE_TIPE_KENDARAAN]: savetipeKendaraan,
  [Types.SAVE_TARIF]: savetarif,
  [Types.SAVE_JENIS_PEMBAYARAN]: savejenispembayaran,
  [Types.SAVE_ORGANIZATION]: saveorganization,
  [Types.SAVE_GATE]: savegate,
  [Types.SAVE_MEMBER]: savemember,
  [Types.SAVE_BRAND_KENDARAAN]: savebrand,
  [Types.SAVE_TENANT]: savetenant,
  // [Types.SAVE_KELUAR_KENDARAAN]: savekeluarkendaraan,
});
