/* ***********************************************************
 * A short word on how to use this syncmagically generated file.
 * We're often asked in the ignite gitter channel how to connect
 * to a to a third party api, so we thought we'd demonstrate - but
 * you should know you can use sagas for other flow control too.
 *
 * Other points:
 *  - You'll need to add this saga to sagas/index.js
 *  - This KeluarKendaraan uses the api declared in sagas/index.js, so
 *    you'll need to define a constant in that file.
 *************************************************************/

import { call, put, select, take } from "redux-saga/effects";
import KeluarKendaraanActions, {
  KeluarKendaraanSelectors,
} from "../reducers/KeluarKendaraanRedux";
import { CheckConnectionSelectors } from "../reducers/CheckConnectionRedux";
import SynchronizeActions, {
  SynchronizeTypes,
} from "../reducers/SynchronizeRedux";
import ErrorRequestActions, {
  ErrorRequestSelectors,
} from "../reducers/ErrorRequestRedux";
import moment from "moment";

export function* keluarKendaraanRequest(api, action) {
  const { data, cb } = action;
  let dataSend = [];
  // get current data from Store
  const currentData = yield select(
    KeluarKendaraanSelectors.getDataKeluarKendaraan
  );

  const currentDataError = yield select(ErrorRequestSelectors.getDataError);

  const currentConnection = yield select(
    CheckConnectionSelectors.getCurrentData
  );
  dataSend = [...currentData, { ...data[0], syncStatus: "loading" }];

  if (currentConnection === false) {
    dataSend = [...currentData, { ...data[0], syncStatus: "local" }];

    if (cb) cb(data);
    return yield put(KeluarKendaraanActions.saveKeluarKendaraan(dataSend));
  }

  const response = yield call(api.keluarKendaraan, data);
  // success?
  if (response.ok) {
    dataSend = [...currentData, { ...data[0], syncStatus: "up" }];

    // yield put(KeluarKendaraanActions.keluarKendaraanSuccess());
    yield put(KeluarKendaraanActions.keluarKendaraanSuccess(dataSend));
    if (currentConnection) {
      yield put(SynchronizeActions.synchronizeDataRequest(data[0].createdBy));
      yield take([
        SynchronizeTypes.SYNCHRONIZE_DATA_SUCCESS,
        SynchronizeTypes.SYNCHRONIZE_DATA_FAILURE,
      ]);
    }
    if (cb) cb(response.data);
  } else {
    const errorInfo = {
      url: `${response.config.baseURL}${response.config.url}`,
      parameter: data,
      method: response.config.method,
      dateRequest: moment().format("yyyy-MM-DD HH:mm:ss"),
    };

    dataSend = [...currentDataError, errorInfo];
    if (cb) cb(response.data);

    yield put(ErrorRequestActions.saveErrorRequest(dataSend));
    // yield put(KeluarKendaraanActions.keluarKendaraanFailure());
  }
}

export function* autoKeluarKendaraanRequest(api, action) {
  const { data } = action;

  const response = yield call(api.keluarKendaraan, data);
  // success?
  if (response.ok) {
    yield put(KeluarKendaraanActions.autoKeluarKendaraanSuccess());
  } else {
    yield put(KeluarKendaraanActions.autoKeluarKendaraanFailure());
  }
}
