import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Button,
  Popover,
  Space,
  Select,
  Typography,
  Divider,
  notification,
} from "antd";
import AuthActions from "../../appRedux/reducers/AuthRedux";
import SynchronizeActions from "../../appRedux/reducers/SynchronizeRedux";
import ProfileImg from "../../assets/profile.png";
import LoadingOverlay from "../LoadingOverlay";
import getModalAlert from "../ModalAlert";

const UserInfo = () => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const userCredent = localStorage.getItem("user_credent")
    ? JSON.parse(localStorage.getItem("user_credent"))
    : null;
  const onChangeOrganization = (value) => {
    localStorage.setItem("user_credent", {
      ...userCredent,
      selectedOrganization: value,
    });
  };

  const { fetching } = useSelector(({ synchronize }) => synchronize);
  const { payload } = useSelector(({ organization }) => organization);

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <LoadingOverlay loading={fetching} />
      {/* <li>My Account</li>
      <li>Connections</li> */}
      <li className="gx-mb-3" style={{ width: "100%" }}>
        <Typography.Text>Organisasi</Typography.Text>
        <Select
          defaultValue={
            payload && payload.length > 0 && payload[0].organizationId
          }
          className="gx-mt-1"
          size="small"
          style={{ width: "100%" }}
          onChange={onChangeOrganization}
        >
          {payload &&
            payload.map((data) => (
              <Option key={data.organizationId} value={data.organizationId}>
                {data.nama}
              </Option>
            ))}
        </Select>
      </li>
      <Divider />
      <li style={{ width: "100%" }}>
        <Space>
          <Button
            onClick={() =>
              dispatch(
                AuthActions.userSignOut({
                  mUserId: userCredent.muserId,
                  mGateId: userCredent.selectedGate,
                })
              )
            }
            type="primary"
          >
            Logout
          </Button>
          <Button
            loading={fetching}
            type="primary"
            onClick={() =>
              dispatch(
                SynchronizeActions.synchronizeDataRequest(
                  userCredent.muserId,
                  (response) => {
                    if (response.message === "Success Synchronize") {
                      notification.success({
                        message: "Sukses",
                        description: response.message,
                      });
                    } else {
                      getModalAlert(
                        response.message
                          ? response.message
                          : "Gagal sinkronisasi data"
                      );
                    }
                  }
                )
              )
            }
          >
            Syncronize
          </Button>
        </Space>
      </li>
    </ul>
  );

  return (
    <Popover
      overlayClassName="gx-popover-horizantal"
      placement="bottomRight"
      content={userMenuOptions}
      trigger="click"
    >
      <img
        src={ProfileImg}
        className="ant-avatar
              ant-avatar-circle       
              gx-size-40"
      />
    </Popover>
  );
};

export default UserInfo;
