import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  saveErrorRequest: ["errorInfo"],
});

export const ErrorRequestTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  error: [],
});

/* ------------- Selectors ------------- */

export const ErrorRequestSelectors = {
  getData: (state) => state.data,
  getDataError: (state) => state.errorrequest.error,
};

/* ------------- Reducers ------------- */

export const errorrequest = (state, action) => {
  const { errorInfo } = action;
  console.log("errorInfo: ", errorInfo);
  return state.merge({ error: errorInfo ? errorInfo : [] });
};
/* ------------- Hookup /Binding Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SAVE_ERROR_REQUEST]: errorrequest,
});
