import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getTarifRequest: ["data"],
  getTarifSuccess: ["payload"],
  getTarifFailure: [],

  saveTarif: ["payload"],
});

export const TarifTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: null,
  fetching: false,
  payload: {},
  error: false,
});

/* ------------- Selectors ------------- */

export const TarifSelectors = {
  getData: (state) => state.data,
};

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state, { data }) =>
  state.merge({ fetching: true, data, error: false });

// successful api lookup
export const success = (state, action) => {
  const { payload } = action;
  return state.merge({ fetching: false, error: false, payload });
};

// Something went wrong somewhere.
export const failure = (state) => {
  state.merge({ fetching: false, error: true });
};

export const saveTarif = (state, action) => {
  const { payload } = action;
  return state.merge({ payload });
};

/* ------------- Hookup /Binding Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_TARIF_REQUEST]: request,
  [Types.GET_TARIF_SUCCESS]: success,
  [Types.GET_TARIF_FAILURE]: failure,

  [Types.SAVE_TARIF]: saveTarif,
});
