import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getTiketMasukByBarcodeRequest: ["data"],
  getTiketMasukByBarcodeSuccess: ["infotiket"],
  getTiketMasukByBarcodeFailure: [],

  openModal: ["isopen"],
});

export const MasukKendaraanTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: null,
  loadingtiket: false,
  infotiket: {},
  error: false,
  isopen: false,
});

/* ------------- Selectors ------------- */

export const MasukKendaraanSelectors = {
  getData: (state) => state.data,
};

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state, { data }) =>
  state.merge({ loadingtiket: true, data, error: false, isopen: true });

// successful api lookup
export const success = (state, action) => {
  const { infotiket } = action;
  return state.merge({
    loadingtiket: false,
    error: false,
    infotiket,
    isopen: false,
  });
};

// Something went wrong somewhere.
export const failure = (state) => {
  return state.merge({ loadingtiket: false, error: true, isopen: false });
};

export const openrequest = (state, action) => {
  const { isopen } = action;
  return state.merge({ isopen });
};

/* ------------- Hookup /Binding Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_TIKET_MASUK_BY_BARCODE_REQUEST]: request,
  [Types.GET_TIKET_MASUK_BY_BARCODE_SUCCESS]: success,
  [Types.GET_TIKET_MASUK_BY_BARCODE_FAILURE]: failure,

  [Types.OPEN_MODAL]: openrequest,
});
