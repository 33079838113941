import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  synchronizeDataRequest: ["userId", "cb"],
  synchronizeDataSuccess: [],
  synchronizeDataFailure: [],

  syncKeluarKendaraanRequest: [],
});

export const SynchronizeTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  userId: null,
  fetching: false,
  payload: {},
  error: false,
});

/* ------------- Selectors ------------- */

export const SynchronizeSelectors = {
  getData: (state) => state.data,
};

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state, { userId }) =>
  state.merge({ fetching: true, userId, error: false });

// successful api lookup
export const success = (state) => {
  return state.merge({ fetching: false, error: false });
};

// Something went wrong somewhere.
export const failure = (state) => {
  return state.merge({ fetching: false, error: true });
};

/* ------------- Hookup /Binding Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SYNCHRONIZE_DATA_REQUEST]: request,
  [Types.SYNCHRONIZE_DATA_SUCCESS]: success,
  [Types.SYNCHRONIZE_DATA_FAILURE]: failure,
});
