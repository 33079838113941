import React, { useState, useEffect } from "react";

function NowDisplay() {
  const [dateState, setDateState] = useState(new Date());
  useEffect(() => {
    setInterval(() => setDateState(new Date()), 30000);
  }, []);
  return (
    <>
      <li className="gx-text-white display-now">
        {dateState.toLocaleString("id", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })}
      </li>

      <li className="gx-text-white display-now">
        {dateState.toLocaleDateString("id", {
          weekday: "long",
          day: "numeric",
          month: "long",
          year: "numeric",
        })}
      </li>
    </>
  );
}

export default NowDisplay;
