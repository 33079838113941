import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  selectGateByUserRequest: ["data"],
  selectGateByUserSuccess: [],
  selectGateByUserFailure: [],

  getOneGateRequest: ["gateId"],
  getOneGateSuccess: ["gate"],
  getOneGateFailure: [],

  saveGate: ["payload"],
});

export const GateTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: null,
  fetching: false,
  error: false,

  gateId: null,
  gate: null,
  fetchingGate: false,
  errorGate: false,

  payload: [],
});

/* ------------- Selectors ------------- */

export const GateSelectors = {
  getData: (state) => state.data,
};

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state, { data }) =>
  state.merge({ fetching: true, data, error: false });

// successful api lookup
export const success = (state, action) => {
  return state.merge({ fetching: false, error: false });
};

// Something went wrong somewhere.
export const failure = (state) => state.merge({ fetching: false, error: true });

// request the data from an api
export const requestGetOne = (state, { gateId }) =>
  state.merge({ fetchingGate: true, gateId, errorGate: false });

// successful api lookup
export const successGetOne = (state, action) => {
  const { gate } = action;
  return state.merge({ fetchingGate: false, errorGate: false, gate });
};

// Something went wrong somewhere.
export const failureGetOne = (state) =>
  state.merge({ fetchingGate: false, errorGate: true });

export const saveGate = (state, action) => {
  const { payload } = action;
  return state.merge({ payload });
};

// successful api lookup

/* ------------- Hookup /Binding Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SELECT_GATE_BY_USER_REQUEST]: request,
  [Types.SELECT_GATE_BY_USER_SUCCESS]: success,
  [Types.SELECT_GATE_BY_USER_FAILURE]: failure,

  [Types.GET_ONE_GATE_REQUEST]: requestGetOne,
  [Types.GET_ONE_GATE_SUCCESS]: successGetOne,
  [Types.GET_ONE_GATE_FAILURE]: failureGetOne,

  [Types.SAVE_GATE]: saveGate,
});
