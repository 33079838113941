import { Modal, Radio, Typography, Space, Button, Alert } from "antd";
import React from "react";

import "./style.css";

const ModalSelectGate = ({
  open,
  handleOk,
  onChange,
  onDoubleClick,
  listGate,
  isAlert,
  loading,
}) => (
  <Modal
    title={<Typography.Title level={3}>Pilih Gate</Typography.Title>}
    open={open}
    okText="Pilih"
    className="modal-select-gate"
    footer={[
      <Button
        size="large"
        key="submit"
        type="primary"
        onClick={handleOk}
        loading={loading}
      >
        Pilih
      </Button>,
    ]}
  >
    {isAlert && (
      <Alert message="Gate harus dipilih!" type="error" showIcon closable />
    )}

    <Radio.Group
      name="tes"
      size="large"
      className="gate-option"
      onChange={onChange}
    >
      {listGate &&
        listGate.length > 0 &&
        listGate.map((data) => (
          <span key={data.mgateId} onDoubleClick={onDoubleClick}>
            <Radio.Button value={data.mgateId}>
              <Space>
                {/* <div onD>LOGO</div> */}

                {data.nama}
              </Space>
            </Radio.Button>
          </span>
        ))}
    </Radio.Group>
  </Modal>
);

export default ModalSelectGate;
