import React, { useEffect } from "react";
import { Button, Col, Form, Input, message, Row, Typography } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Slideshow from "../components/SlideShow";
import "./style.css";
import { hideMessage, showAuthLoader } from "../appRedux/actions";
import LOGO_NUSA_PARKING from "../assets/images/logo-nusa-parking.png";

import AuthActions from "../appRedux/reducers/AuthRedux";

import CircularProgress from "../components/CircularProgress";

const SignIn = () => {
  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );
  const history = useHistory();

  useEffect(() => {
    // if (showMessage) {
    //   setTimeout(() => {
    //     dispatch(hideMessage());
    //   }, 100);
    // }
    if (authUser !== null) {
      history.push("/");
    }
  }, [authUser, history]);

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    dispatch(AuthActions.signinUser(values, history));
    // dispatch(showAuthLoader());
    // dispatch(userSignIn(values));
  };

  return (
    <div className="container login-page">
      <Row className="login-page-main">
        {/* {loader ? (
          <div
            className="gx-loader-view"
            style={{
              position: "absolute",
              zIndex: 99,
              left: "50%",
            }}
          >
            <CircularProgress />
          </div>
        ) : null} */}
        <Col xs={{ span: 24 }} lg={{ span: 14 }} className="login-page-hero">
          <img alt="Logo Nusa Parking" src={LOGO_NUSA_PARKING} />
          {/* <Row> */}
          {/* <Col span={24} className="logo-wrap"> */}
          {/* <h1 className="gx-text-white">E - PARKING</h1> */}

          {/* </Col> */}
          {/* <Col span={24} className="slide-show-wrap">
              <Slideshow />
            </Col> */}
          {/* </Row> */}
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 10 }} className="login-form">
          <Form
            // name="normal_login"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item>
              <div className="text-sigin">Sign In to</div>
              <div className="text-sigin">Nusa Parking</div>
            </Form.Item>
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your Username!",
                },
              ]}
            >
              <Input
                className="form-input-login"
                // prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Username"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input
                className="form-input-login"
                // prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>

            <Form.Item>
              <Button
                loading={loader}
                htmlType="submit"
                className="login-form-button"
              >
                {loader ? "Please waiting..." : "Masuk"}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      {/* {loader ? (
        <div className="gx-loader-view">
          <CircularProgress />
          <h1>TEs</h1>
        </div>
      ) : null}
      {showMessage ? message.error(alertMessage.toString()) : null} */}
    </div>
  );
};

export default SignIn;
