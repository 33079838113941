import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  changeStatusConnection: ["isConnected"],
});

export const CheckConnectionTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  isConnected: true,
});

/* ------------- Selectors ------------- */

export const CheckConnectionSelectors = {
  getData: (state) => state.data,
  getCurrentData: (state) => state.checkconnection.isConnected,
};

/* ------------- Reducers ------------- */

export const changestatus = (state, action) => {
  const { isConnected } = action;

  return state.merge({ isConnected });
};
/* ------------- Hookup /Binding Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CHANGE_STATUS_CONNECTION]: changestatus,
});
