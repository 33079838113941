import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getOneJenisPembayaranRequest: ["data"],
  getOneJenisPembayaranSuccess: ["payment"],
  getOneJenisPembayaranFailure: [],

  openModalPayment: ["isopen"],
  clearPayment: [],

  saveJenisPembayaran: ["payload"],
});

export const JenisPembayaranTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: null,
  fetching: false,
  payment: {},
  error: false,

  isopen: false,

  payload: [],
});

/* ------------- Selectors ------------- */

export const JenisPembayaranSelectors = {
  getData: (state) => state.data,
};

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state, { data }) =>
  state.merge({ fetching: true, data, error: false, isopen: true });

// successful api lookup
export const success = (state, action) => {
  const { payment } = action;
  return state.merge({
    fetching: false,
    error: false,
    payment,
    isopen: false,
  });
};

// Something went wrong somewhere.
export const failure = (state) => {
  return state.merge({ fetching: false, error: true, isopen: false });
};

export const openRequest = (state, action) => {
  const { isopen } = action;
  return state.merge({ isopen });
};

export const clearRequest = (state) => {
  return state.merge({ payment: null });
};

export const saveJenisPembayaran = (state, action) => {
  const { payload } = action;
  return state.merge({ payload });
};
/* ------------- Hookup /Binding Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ONE_JENIS_PEMBAYARAN_REQUEST]: request,
  [Types.GET_ONE_JENIS_PEMBAYARAN_SUCCESS]: success,
  [Types.GET_ONE_JENIS_PEMBAYARAN_FAILURE]: failure,

  [Types.OPEN_MODAL_PAYMENT]: openRequest,
  [Types.CLEAR_PAYMENT]: clearRequest,

  [Types.SAVE_JENIS_PEMBAYARAN]: saveJenisPembayaran,
});
