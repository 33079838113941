import React from "react";
import { Detector } from "react-detect-offline";
import "./style.css";

const CheckConnection = ({ onChangeConnection, render }) => (
  <Detector
    onChange={onChangeConnection}
    render={
      render
      // <div className={online ? "normal" : "warning"}>
      //   You are currently {online ? "online" : "offline"}
      // </div>
    }
  />
);

export default CheckConnection;
