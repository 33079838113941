/* ***********************************************************
 * A short word on how to use this automagically generated file.
 * We're often asked in the ignite gitter channel how to connect
 * to a to a third party api, so we thought we'd demonstrate - but
 * you should know you can use sagas for other flow control too.
 *
 * Other points:
 *  - You'll need to add this saga to sagas/index.js
 *  - This Member uses the api declared in sagas/index.js, so
 *    you'll need to define a constant in that file.
 *************************************************************/

// import { message } from "antd";
import { call, put, take } from "redux-saga/effects";
import MemberActions from "../reducers/MemberRedux";
import TarifActions, { TarifTypes } from "../reducers/TarifRedux";
// import { MemberSelectors } from '../Redux/MemberRedux'

export function* getStatusMemberByCodeRequest(api, action) {
  // message.loading({
  //   content: "Loading...",
  // });
  const { data } = action;
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api

  const response = yield call(api.getStatusMemberByCode, data.nomorPlat);
  // success?
  if (response.ok) {
    yield put(
      TarifActions.getTarifRequest({
        mTipeKendaraanId: data.mTipeKendaraanId,
        tipeTarif: response.data.data.kode,
      })
    );
    yield take([TarifTypes.GET_TARIF_SUCCESS, TarifTypes.GET_TARIF_FAILURE]);
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log("Member response.data", response.data);
    yield put(MemberActions.getStatusMemberByCodeSuccess(response.data.data));
    // message.destroy();
  } else {
    // message.destroy();
    yield put(MemberActions.getStatusMemberByCodeFailure());
  }
}
