import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  keluarKendaraanRequest: ["data", "cb"],
  keluarKendaraanSuccess: ["payload"],
  keluarKendaraanFailure: [],

  autoKeluarKendaraanRequest: ["data"],
  autoKeluarKendaraanSuccess: ["payload"],
  autoKeluarKendaraanFailure: [],

  saveKeluarKendaraan: ["payload"],
});

export const KeluarKendaraanTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: null,
  loading: false,
  payload: [],
  error: false,
});

/* ------------- Selectors ------------- */

export const KeluarKendaraanSelectors = {
  getData: (state) => state.data,
  getDataKeluarKendaraan: (state) => state.keluarkendaraan.payload,
};

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state, { data }) =>
  state.merge({ loading: true, data, error: false });

// successful api lookup
export const success = (state, action) => {
  const { payload } = action;
  return state.merge({
    loading: false,
    error: false,
    payload: payload ? payload : [],
  });
};

// Something went wrong somewhere.
export const failure = (state) => {
  return state.merge({ loading: false, error: true });
};

export const requestKeluar = (state, { data }) =>
  state.merge({ loading: true, data, error: false });

// successful api lookup
export const successKeluar = (state, action) => {
  return state.merge({
    loading: false,
    error: false,
  });
};

// Something went wrong somewhere.
export const failureKeluar = (state) => {
  return state.merge({ loading: false, error: true });
};

export const saveKeluarKendaraan = (state, action) => {
  const { payload } = action;

  return state.merge({
    payload: payload ? payload : [],
  });
};

/* ------------- Hookup /Binding Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.KELUAR_KENDARAAN_REQUEST]: request,
  [Types.KELUAR_KENDARAAN_SUCCESS]: success,
  [Types.KELUAR_KENDARAAN_FAILURE]: failure,

  [Types.AUTO_KELUAR_KENDARAAN_REQUEST]: requestKeluar,
  [Types.AUTO_KELUAR_KENDARAAN_SUCCESS]: successKeluar,
  [Types.AUTO_KELUAR_KENDARAAN_FAILURE]: failureKeluar,

  [Types.SAVE_KELUAR_KENDARAAN]: saveKeluarKendaraan,
});
