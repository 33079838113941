// a library to wrap and simplify api calls
import apisauce from "apisauce";
import config from "../util/config";
import { notification, Modal, Row, Col } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { store } from "../NextApp";
import AuthActions from "../appRedux/reducers/AuthRedux";
// our "constructor"
const create = (baseURL = config.BASE_URL) => {
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //
  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    headers: {
      // 'Cache-Control': 'no-cache',
      // 'Content-Type': 'application/json',
    },
    // 10 second timeout...
    timeout: 50000 /* * 30 */, //di uncoment = 30 menit
  });

  api.addAsyncRequestTransform(async (request) => {
    // console.log("CURRENT AUTH TOKEN di DB", request);
    // request.headers['AuthToken'] = idTokenResult
  });

  api.addResponseTransform(async (response) => {
    let url = response.config.url.split("/");
    let respUrl = `${url[0]}/${url[1]}`;
    if (["system/synchronize", "keluarKendaraan/create"].includes(respUrl)) {
      return;
    }

    if (!response.ok && response.data) {
      Modal.error({
        centered: true,
        icon: <ExclamationCircleFilled />,
        okType: "danger",
        content: (
          <div>
            <p>
              {response.data.message
                ? response.data.message
                : "Unknown Error From Backend (Code: 1)"}
            </p>
          </div>
        ),
        title: (
          <Row
            type="flex"
            justify="start"
            style={{ alignItems: "center" }}
            gutter={[5, 0]}
          >
            <Col>
              <span>Warnings !</span>
            </Col>
          </Row>
        ),
        onOk() {},
        onCancel() {},
      });
      if (
        response.data &&
        response.data.message &&
        response.data.message ===
          "Full authentication is required to access this resource"
      ) {
        store.dispatch(AuthActions.userSignOut());
      }
    }

    // if (
    //   !response.ok &&
    //   response.problem &&
    //   response.problem === "TIMEOUT_ERROR"
    // ) {
    //   notification.error({
    //     message: "Error",
    //     description: "Gagal Terkoneksi ke server, harap periksa koneksi",
    //   });
    // }

    // if (
    //   !response.ok &&
    //   response.problem &&
    //   response.problem === "NETWORK_ERROR"
    // ) {
    //   notification.error({
    //     message: "Error",
    //     description: "Tidak Dapat Terhubung ke Server",
    //   });
    // }

    if (response.ok && response.data && response.data.error) {
      Modal.error({
        centered: true,
        icon: <ExclamationCircleFilled />,
        okType: "danger",
        content: (
          <div>
            <p>
              {response.data.message
                ? response.data.message
                : "Unknown Error From Backend (Code: 1)"}
            </p>
          </div>
        ),
        title: (
          <Row
            type="flex"
            justify="start"
            style={{ alignItems: "center" }}
            gutter={[5, 0]}
          >
            <Col>
              <span>Warnings !</span>
            </Col>
          </Row>
        ),
        onOk() {},
        onCancel() {},
      });
      // notification.error({
      //   message: "Error",
      //   description: response.data.message
      //     ? response.data.message
      //     : "Unknown Error From Backend (Code: 2)",
      // });
    }
  });

  // ------
  // STEP 2
  // ------
  //
  // Define some functions that call the api.  The goal is to provide
  // a thin wrapper of the api layer providing nicer feeling functions
  // rather than "get", "post" and friends.
  //
  // I generally don't like wrapping the output at this level because
  // sometimes specific actions need to be take on `403` or `401`, etc.
  //
  // Since we can't hide from that, we embrace it by getting out of the
  // way at this level.
  //
  const templateRequest = (data) => api.get("typicode/demo/comments", data);
  const getComment = (data) => api.get("typicode/demo/comments", data);

  // Auth
  const authLogin = (data) => api.post("system/login", data);
  const authLogout = (data) =>
    api.post(`system/logout/${data.mUserId}/${data.mGateId}`);
  const synchronizeData = (mUserId) =>
    api.post(`system/synchronize/${mUserId}`);

  // Gate
  const selectGateByUser = (data) =>
    api.post(`gate/pilih/${data.mGateId}/${data.mUserId}`);
  const getOneGate = (gateId) => api.get(`gate/findOne/${gateId}`);

  // Member
  const getStatusMemberByCode = (kodeMember) =>
    api.get(`member/checkMember/${kodeMember}`);

  // Tarif
  const getTarif = (data) =>
    api.get(`tarif/cekTarif/${data.mTipeKendaraanId}/${data.tipeTarif}`);

  // Brand Kendaraan
  const getBrandKendaraan = (mTipeKendaraanId) =>
    api.get(`brandKendaraan/findByTipeKendaraan/${mTipeKendaraanId}`);

  const getOneBrandKendaraan = (mBrandKendaraanId) =>
    api.get(`/brandKendaraan/findOne/${mBrandKendaraanId}`);

  // Masuk Kendaraan
  const getTiketMasukByBarcode = (kodeBarcode) =>
    api.get(`masukKendaraan/findByTicket/${kodeBarcode}`);

  // Keluar Kendaraan
  const keluarKendaraan = (data) => api.post(`keluarKendaraan/create`, data);

  // Jenis Pembayaran
  const getOneJenisPembayaran = (mJenisPembayaranId) =>
    api.get(`jenisPembayaran/findOne/${mJenisPembayaranId}`);

  //
  return {
    // a list of the API functions from step 2
    templateRequest,
    getComment,

    authLogin,
    authLogout,
    synchronizeData,

    selectGateByUser,
    getOneGate,

    getStatusMemberByCode,

    getTarif,

    getBrandKendaraan,
    getOneBrandKendaraan,

    getTiketMasukByBarcode,

    keluarKendaraan,

    getOneJenisPembayaran,
  };
};

// let's return back our create method as the default.
export default {
  create,
};
