import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Settings from "./Settings";
// import Auth from "./Auth";
import Notes from "./Notes";
import Contact from "./Contact";
import Common from "./Common";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    // auth: Auth,
    notes: Notes,
    contact: Contact,
    common: Common,
    //kbij redux
    template: require("./TemplateRedux").reducer,
    comments: require("./CommentsRedux").reducer,
    modalinfo: require("./ModalInfoRedux").reducer,
    loadingoverlay: require("./LoadingOverlayRedux").reducer,
    auth: require("./AuthRedux").reducer,
    gate: require("./GateRedux").reducer,
    member: require("./MemberRedux").reducer,
    tipekendaraan: require("./TipeKendaraanRedux").reducer,
    tarif: require("./TarifRedux").reducer,
    jenispembayaran: require("./JenisPembayaranRedux").reducer,
    organization: require("./OrganizationRedux").reducer,
    brandkendaraan: require("./BrandKendaraanRedux").reducer,
    masukkendaraan: require("./MasukKendaraanRedux").reducer,
    keluarkendaraan: require("./KeluarKendaraanRedux").reducer,
    tenant: require("./TenantRedux").reducer,
    local: require("./LocalRedux").reducer,
    synchronize: require("./SynchronizeRedux").reducer,
    checkconnection: require("./CheckConnectionRedux").reducer,
    errorrequest: require("./ErrorRequestRedux").reducer,
  });

export default createRootReducer;
