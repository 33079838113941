import React, { useState, useEffect } from "react";
import { Col, Layout, Popover, Row, Typography } from "antd";
import { Link, useParams } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import languageData from "./languageData";
import { switchLanguage, toggleCollapsedSideNav } from "../../appRedux/actions";
import SearchBox from "../../components/SearchBox";
import UserInfo from "../../components/UserInfo";
import AppNotification from "../../components/AppNotification";
import MailNotification from "../../components/MailNotification";
import Auxiliary from "util/Auxiliary";
import NowDisplay from "../../components/NowDisplay";
import GateActions from "../../appRedux/reducers/GateRedux";

import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";

const { Header } = Layout;

const Topbar = () => {
  const now = Date.now();
  const { locale, navStyle } = useSelector(({ settings }) => settings);
  const navCollapsed = useSelector(({ common }) => common.navCollapsed);
  const width = useSelector(({ common }) => common.width);

  const dispatch = useDispatch();
  const userCredent = JSON.parse(localStorage.getItem("user_credent"));

  return (
    <>
      <Header>
        <Link to="/" className="gx-d-block  gx-pointer">
          {/* <img alt="" src={"/assets/images/w-logo.png"} />x */}
          <Typography.Title level={4} className="gx-text-white gx-pt-2">
            E - PARKING
          </Typography.Title>
        </Link>
        <ul className="gx-header-notifications gx-ml-auto">
          {/* <li className="gx-notify gx-notify-search gx-d-inline-block gx-d-lg-none">
            <Popover
              overlayClassName="gx-popover-horizantal"
              placement="bottomRight"
              content={
                <SearchBox
                  styleName="gx-popover-search-bar"
                  placeholder="Search in app..."
                  onChange={updateSearchChatUser}
                  value={searchText}
                />
              }
              trigger="click"
            >
              <span className="gx-pointer gx-d-block">
                <i className="icon icon-search-new" />
              </span>
            </Popover>
          </li> */}
          {width >= TAB_SIZE ? null : (
            <Auxiliary>
              <li className="gx-notify">
                <Popover
                  overlayClassName="gx-popover-horizantal"
                  placement="bottomRight"
                  content={<AppNotification />}
                  trigger="click"
                >
                  {/* <span className="gx-pointer gx-d-block">
                  <i className="icon icon-notification" />
                </span> */}
                </Popover>
              </li>

              {/* <li className="gx-msg">
              <Popover
                overlayClassName="gx-popover-horizantal"
                placement="bottomRight"
                content={<MailNotification />}
                trigger="click"
              >
                <span className="gx-pointer gx-status-pos gx-d-block">
                  <i className="icon icon-chat-new" />
                  <span className="gx-status gx-status-rtl gx-small gx-orange" />
                </span>
              </Popover>
            </li> */}
            </Auxiliary>
          )}
          {/* <li className="gx-language">
            <Popover
              overlayClassName="gx-popover-horizantal"
              placement="bottomRight"
              content={languageMenu()}
              trigger="click"
            >
              <span className="gx-pointer gx-flex-row gx-align-items-center">
                <i className={`flag flag-24 flag-${locale.icon}`} />
                <span className="gx-pl-2 gx-language-name">{locale.name}</span>
                <i className="icon icon-chevron-down gx-pl-2" />
              </span>
            </Popover>
          </li> */}
          {/* <li className="gx-text-white">
           
          </li> */}
          {/* <li className="gx-text-white">Selasa 16 November 2022</li> */}
          <NowDisplay />
          <li className="gx-text-white">{userCredent.selectedGateName}</li>
          {/* {width >= TAB_SIZE ? null : ( */}
          <Auxiliary>
            <li className="gx-user-nav">
              <UserInfo />
            </li>
          </Auxiliary>

          {/* )} */}
        </ul>
      </Header>
    </>
  );
};

export default Topbar;
