import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  saveOrganization: ["payload"],
});

export const OrganizationTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  payload: [],
});

/* ------------- Selectors ------------- */

export const OrganizationSelectors = {
  getData: (state) => state.data,
};

/* ------------- Reducers ------------- */

export const saveOrganization = (state, action) => {
  const { payload } = action;
  return state.merge({ payload });
};

/* ------------- Hookup /Binding Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SAVE_ORGANIZATION]: saveOrganization,
});
