/* ***********************************************************
 * A short word on how to use this automagically generated file.
 * We're often asked in the ignite gitter channel how to connect
 * to a to a third party api, so we thought we'd demonstrate - but
 * you should know you can use sagas for other flow control too.
 *
 * Other points:
 *  - You'll need to add this saga to sagas/index.js
 *  - This Gate uses the api declared in sagas/index.js, so
 *    you'll need to define a constant in that file.
 *************************************************************/

import { call, put, select, take } from "redux-saga/effects";
import SynchronizeActions from "../reducers/SynchronizeRedux";
import TipeKendaraanActions from "../reducers/TipeKendaraanRedux";
import TarifActions from "../reducers/TarifRedux";
import JenisPembayaranActions from "../reducers/JenisPembayaranRedux";
import OrganizationActions from "../reducers/OrganizationRedux";
import GateActions from "../reducers/GateRedux";
import MemberActions from "../reducers/MemberRedux";
import BrandKendaraanActions from "../reducers/BrandKendaraanRedux";
import TenantActions from "../reducers/TenantRedux";
import KeluarKendaraanActions, {
  KeluarKendaraanTypes,
  KeluarKendaraanSelectors,
} from "../reducers/KeluarKendaraanRedux";
// import LocalActions from "../reducers/LocalRedux";

import _ from "lodash";

export function* synchronizeDataRequest(api, action) {
  const { userId, cb } = action;
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.synchronizeData, userId);
  // success?
  if (response.ok) {
    // save data state
    yield put(
      TipeKendaraanActions.saveTipeKendaraan(response.data.data.tipeKendaraan)
    );
    yield put(
      TarifActions.saveTarif(
        _.orderBy(response.data.data.tarif, ["tanggalBerlaku"], ["desc"])
      )
    );
    yield put(
      JenisPembayaranActions.saveJenisPembayaran(
        response.data.data.jenisPembayaran
      )
    );
    yield put(
      OrganizationActions.saveOrganization(response.data.data.organization)
    );
    yield put(GateActions.saveGate(response.data.data.gate));
    yield put(MemberActions.saveMember(response.data.data.member));
    yield put(
      BrandKendaraanActions.saveBrandKendaraan(
        response.data.data.brandKendaraan
      )
    );
    yield put(TenantActions.saveTenant(response.data.data.tenant));
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    yield put(SynchronizeActions.synchronizeDataSuccess());
    if (cb) cb(response.data);
  } else {
    yield put(SynchronizeActions.synchronizeDataFailure());
    if (cb) cb(response.data);
  }
}

export function* syncKeluarKendaraanRequest(api, action) {
  let dataSend = [];
  const currentData = yield select(
    KeluarKendaraanSelectors.getDataKeluarKendaraan
  );

  const dataLocalKeluarKendaraan =
    currentData.length > 0 &&
    currentData.filter((x) => x.syncStatus === "local");

  if (dataLocalKeluarKendaraan.length > 0) {
    for (const item of dataLocalKeluarKendaraan) {
      dataSend = [{ ...item, syncStatus: "loading" }];
      const currentDataObject = _.keyBy(currentData, "keluarKendaraanId");
      let incomingData;
      let newPayload = {};
      let newPayloadArray = [];

      yield put(KeluarKendaraanActions.autoKeluarKendaraanRequest(dataSend));
      const response = yield take([
        KeluarKendaraanTypes.AUTO_KELUAR_KENDARAAN_SUCCESS,
        KeluarKendaraanTypes.AUTO_KELUAR_KENDARAAN_FAILURE,
      ]);

      if (response.type === "AUTO_KELUAR_KENDARAAN_SUCCESS") {
        dataSend = [{ ...item, syncStatus: "up" }];
        incomingData = _.keyBy(dataSend, "keluarKendaraanId");

        newPayload = {
          ...currentDataObject,
          ...incomingData,
        };
        newPayloadArray = _.values(newPayload);
        yield put(KeluarKendaraanActions.saveKeluarKendaraan(newPayloadArray));
      } else {
        dataSend = [{ ...item[0], syncStatus: "local" }];
        incomingData = _.keyBy(dataSend, "keluarKendaraanId");

        newPayload = {
          ...currentDataObject,
          ...incomingData,
        };
        newPayloadArray = _.values(newPayload);
        yield put(KeluarKendaraanActions.saveKeluarKendaraan(newPayloadArray));
      }
    }
  }
}
