import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getStatusMemberByCodeRequest: ["data"],
  getStatusMemberByCodeSuccess: ["status"],
  getStatusMemberByCodeFailure: [],

  saveMember: ["payload"],
});

export const MemberTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: null,
  fetching: false,
  status: null,
  error: false,

  payload: [],
});

/* ------------- Selectors ------------- */

export const MemberSelectors = {
  getData: (state) => state.data,
};

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state, { data }) =>
  state.merge({ fetching: true, data, error: false });

// successful api lookup
export const success = (state, action) => {
  const { status } = action;
  return state.merge({ fetching: false, error: false, status });
};

// Something went wrong somewhere.
export const failure = (state) => {
  state.merge({ fetching: false, error: true });
};

export const saveMember = (state, action) => {
  const { payload } = action;
  return state.merge({ payload });
};

/* ------------- Hookup /Binding Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_STATUS_MEMBER_BY_CODE_REQUEST]: request,
  [Types.GET_STATUS_MEMBER_BY_CODE_SUCCESS]: success,
  [Types.GET_STATUS_MEMBER_BY_CODE_FAILURE]: failure,

  [Types.SAVE_MEMBER]: saveMember,
});
