import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getBrandKendaraanRequest: ["data"],
  getBrandKendaraanSuccess: ["payload"],
  getBrandKendaraanFailure: [],

  getOneBrandKendaraanRequest: ["dataBrand"],
  getOneBrandKendaraanSuccess: ["payloadBrand"],
  getOneBrandKendaraanFailure: [],

  openModalBrand: ["isopen"],
  clearBrand: [],

  saveBrandKendaraan: ["payload"],
});

export const BrandKendaraanTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: null,
  fetching: false,
  payload: [],
  error: false,

  dataBrand: null,
  fetchingBrand: false,
  payloadBrand: {},
  errorBrand: false,
  isopen: false,
});

/* ------------- Selectors ------------- */

export const BrandKendaraanSelectors = {
  getData: (state) => state.data,
};

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state, { data }) =>
  state.merge({ fetching: true, data, error: false });

// successful api lookup
export const success = (state, action) => {
  const { payload } = action;
  return state.merge({ fetching: false, error: false, payload });
};

// Something went wrong somewhere.
export const failure = (state) => {
  state.merge({ fetching: false, error: true });
};

export const requestBrand = (state, { dataBrand }) =>
  state.merge({
    fetchingBrand: true,
    dataBrand,
    errorBrand: false,
    isopen: true,
  });

// successful api lookup
export const successBrand = (state, action) => {
  const { payloadBrand } = action;
  return state.merge({
    fetchingBrand: false,
    errorBrand: false,
    payloadBrand,
    isopen: false,
  });
};

// Something went wrong somewhere.
export const failureBrand = (state) => {
  state.merge({ fetchingBrand: false, errorBrand: true, isopen: false });
};

export const openRequest = (state, action) => {
  const { isopen } = action;
  return state.merge({ isopen });
};

export const clearRequest = (state) => {
  return state.merge({ payloadBrand: null });
};

export const saveBrand = (state, action) => {
  const { payload } = action;
  return state.merge({ payload });
};
/* ------------- Hookup /Binding Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_BRAND_KENDARAAN_REQUEST]: request,
  [Types.GET_BRAND_KENDARAAN_SUCCESS]: success,
  [Types.GET_BRAND_KENDARAAN_FAILURE]: failure,

  [Types.GET_ONE_BRAND_KENDARAAN_REQUEST]: requestBrand,
  [Types.GET_ONE_BRAND_KENDARAAN_SUCCESS]: successBrand,
  [Types.GET_ONE_BRAND_KENDARAAN_FAILURE]: failureBrand,

  [Types.OPEN_MODAL_BRAND]: openRequest,
  [Types.CLEAR_BRAND]: clearRequest,

  [Types.SAVE_BRAND_KENDARAAN]: saveBrand,
});
