import { takeLatest, all } from "redux-saga/effects";
import API from "../../services/Api";
import ApiSample from "../../services/ApiSample";

/* ------------- Types ------------- */

import { TemplateTypes } from "../reducers/TemplateRedux";
import { AuthTypes } from "../reducers/AuthRedux";
import { CommentsTypes } from "../reducers/CommentsRedux";
import { GateTypes } from "../reducers/GateRedux";
import { MemberTypes } from "../reducers/MemberRedux";
import { TarifTypes } from "../reducers/TarifRedux";
import { BrandKendaraanTypes } from "../reducers/BrandKendaraanRedux";
import { MasukKendaraanTypes } from "../reducers/MasukKendaraanRedux";
import { KeluarKendaraanTypes } from "../reducers/KeluarKendaraanRedux";
import { JenisPembayaranTypes } from "../reducers/JenisPembayaranRedux";
import { SynchronizeTypes } from "../reducers/SynchronizeRedux";
/* ------------- Sagas ------------- */

import { templateRequest } from "./TemplateSagas";
import { signinUser, userSignOut } from "./AuthSagas";
import { getRequest } from "./CommentsSagas";
import { selectGateByUserRequest, getOneGateRequest } from "./GateSagas";
import { getStatusMemberByCodeRequest } from "./MemberSagas";
import { getTarifRequest } from "./TarifSagas";
import {
  getBrandKendaraanRequest,
  getOneBrandKendaraanRequest,
} from "./BrandKendaraanSagas";
import { getTiketMasukByBarcodeRequest } from "./MasukKendaraanSagas";
import {
  keluarKendaraanRequest,
  autoKeluarKendaraanRequest,
} from "./KeluarKendaraanSagas";
import { getOneJenisPembayaranRequest } from "./JenisPembayaranSagas";
import {
  synchronizeDataRequest,
  syncKeluarKendaraanRequest,
} from "./SynchronizeSagas";

/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
const api = API.create();
const apisample = ApiSample.create();

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  // console.log('ROOT sagas JALAN !')
  yield all([
    takeLatest(TemplateTypes.TEMPLATE_REQUEST, templateRequest, api),
    takeLatest(AuthTypes.SIGNIN_USER, signinUser, api),
    takeLatest(AuthTypes.USER_SIGN_OUT, userSignOut, api),

    //Comment
    takeLatest(CommentsTypes.GET_REQUEST, getRequest, api),

    // Gate
    takeLatest(
      GateTypes.SELECT_GATE_BY_USER_REQUEST,
      selectGateByUserRequest,
      api
    ),
    takeLatest(GateTypes.GET_ONE_GATE_REQUEST, getOneGateRequest, api),

    // Member
    takeLatest(
      MemberTypes.GET_STATUS_MEMBER_BY_CODE_REQUEST,
      getStatusMemberByCodeRequest,
      api
    ),

    // Tarif
    takeLatest(TarifTypes.GET_TARIF_REQUEST, getTarifRequest, api),

    // Brand Kendaraan
    takeLatest(
      BrandKendaraanTypes.GET_BRAND_KENDARAAN_REQUEST,
      getBrandKendaraanRequest,
      api
    ),
    takeLatest(
      BrandKendaraanTypes.GET_ONE_BRAND_KENDARAAN_REQUEST,
      getOneBrandKendaraanRequest,
      api
    ),

    // Masuk Kendaraan
    takeLatest(
      MasukKendaraanTypes.GET_TIKET_MASUK_BY_BARCODE_REQUEST,
      getTiketMasukByBarcodeRequest,
      api
    ),

    // Masuk Kendaraan
    takeLatest(
      KeluarKendaraanTypes.KELUAR_KENDARAAN_REQUEST,
      keluarKendaraanRequest,
      api
    ),
    takeLatest(
      KeluarKendaraanTypes.AUTO_KELUAR_KENDARAAN_REQUEST,
      autoKeluarKendaraanRequest,
      api
    ),

    // Jenis Pembayaran
    takeLatest(
      JenisPembayaranTypes.GET_ONE_JENIS_PEMBAYARAN_REQUEST,
      getOneJenisPembayaranRequest,
      api
    ),

    // Synchronize
    takeLatest(
      SynchronizeTypes.SYNCHRONIZE_DATA_REQUEST,
      synchronizeDataRequest,
      api
    ),

    takeLatest(
      SynchronizeTypes.SYNC_KELUAR_KENDARAAN_REQUEST,
      syncKeluarKendaraanRequest,
      api
    ),
  ]);
}
