/* ***********************************************************
 * A short word on how to use this automagically generated file.
 * We're often asked in the ignite gitter channel how to connect
 * to a to a third party api, so we thought we'd demonstrate - but
 * you should know you can use sagas for other flow control too.
 *
 * Other points:
 *  - You'll need to add this saga to sagas/index.js
 *  - This Gate uses the api declared in sagas/index.js, so
 *    you'll need to define a constant in that file.
 *************************************************************/

import { notification } from "antd";
import { call, put, take } from "redux-saga/effects";
import GateActions, { GateTypes } from "../reducers/GateRedux";
// import { GateSelectors } from '../Redux/GateRedux'

export function* selectGateByUserRequest(api, action) {
  const { data } = action;
  const userCredent = JSON.parse(localStorage.getItem("user_credent"));
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  yield put(GateActions.getOneGateRequest(data.mGateId));
  const dataGate = yield take([
    GateTypes.GET_ONE_GATE_SUCCESS,
    GateTypes.GET_ONE_GATE_FAILURE,
  ]);

  // if (dataGate.type === "GET_ONE_GATE_SUCCESS") {
  localStorage.setItem(
    "user_credent",
    JSON.stringify({
      ...userCredent,
      selectedGateName: dataGate.gate.nama,
      selectedGate: data.mGateId,
    })
  );
  // }
  const response = yield call(api.selectGateByUser, data);
  // success?
  if (response.ok) {
    notification.success({
      message: "Sukses",
      description: response.data.message,
    });
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    // console.log("Gate response.data", response.data);

    yield put(GateActions.selectGateByUserSuccess());
  } else {
    yield put(GateActions.selectGateByUserFailure());
  }
}

export function* getOneGateRequest(api, action) {
  const { gateId } = action;
  // get current data from Store
  // const currentData = yield select(GetAlatPengamanSelectors.getData)
  // make the call to the api
  const response = yield call(api.getOneGate, gateId);
  // success?
  if (response.ok) {
    // You might need to change the response here - do this with a 'transform',
    // located in ../Transforms/. Otherwise, just pass the data back from the api.
    yield put(GateActions.getOneGateSuccess(response.data.data));
  } else {
    yield put(GateActions.getOneGateFailure());
  }
}
