import React from "react";
import { Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route
        path={`${match.url}parking_gate`}
        component={asyncComponent(() => import("./ParkingGate"))}
      />
      <Route
        path={`${match.url}keluar_kendaraan/:category`}
        component={asyncComponent(() => import("./KeluarKendaraan"))}
      />
    </Switch>
  </div>
);

export default App;
