import React from "react";
import "./style.css";
const LoadingOverlay = ({ loading }) => {
  return (
    <>
      {loading && (
        <div
          className="loading-overlay"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            padding: 0,
            margin: 0,
            top: 0,
            left: 0,
            zIndex: 999,
          }}
        ></div>
      )}
    </>
  );
};

export default LoadingOverlay;
