import React, { useEffect, useState } from "react";
import { Layout, message, Modal, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import HorizontalDefault from "../Topbar/HorizontalDefault/index";
import HorizontalDark from "../Topbar/HorizontalDark/index";
import InsideHeader from "../Topbar/InsideHeader/index";
import AboveHeader from "../Topbar/AboveHeader/index";
import { useHistory } from "react-router-dom";
import CheckConnection from "../../components/CheckConnection";
import { useInterval } from "react-interval-hook";

import BelowHeader from "../Topbar/BelowHeader/index";
import Topbar from "../Topbar/index";
import config, { footerText } from "../../util/config";
import App from "../../routes/index";
import ModalSelectGate from "../../components/ModalSelectGate";
import SynchronizeActions from "../../appRedux/reducers/SynchronizeRedux";
import CheckConnectionActions from "../../appRedux/reducers/CheckConnectionRedux";
import GateActions from "../../appRedux/reducers/GateRedux";

import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
} from "../../constants/ThemeSetting";
import NoHeaderNotification from "../Topbar/NoHeaderNotification/index";
import { useRouteMatch } from "react-router-dom";
import { updateWindowWidth } from "../../appRedux/actions";
import AppSidebar from "./AppSidebar";

import AuthActions from "../../appRedux/reducers/AuthRedux";
import { useIdleTimer } from "react-idle-timer";
import moment from "moment";

const { Content, Footer } = Layout;

const getContainerClass = (navStyle) => {
  switch (navStyle) {
    case NAV_STYLE_DARK_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_DEFAULT_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_BELOW_HEADER:
      return "gx-container-wrap";
    case NAV_STYLE_ABOVE_HEADER:
      return "gx-container-wrap";
    default:
      return "";
  }
};

const getNavStyles = (navStyle) => {
  switch (navStyle) {
    case NAV_STYLE_DEFAULT_HORIZONTAL:
      return <HorizontalDefault />;
    case NAV_STYLE_DARK_HORIZONTAL:
      return <HorizontalDark />;
    case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
      return <InsideHeader />;
    case NAV_STYLE_ABOVE_HEADER:
      return <AboveHeader />;
    case NAV_STYLE_BELOW_HEADER:
      return <BelowHeader />;
    case NAV_STYLE_FIXED:
      return <Topbar />;
    case NAV_STYLE_DRAWER:
      return <Topbar />;
    case NAV_STYLE_MINI_SIDEBAR:
      return <Topbar />;
    case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
      return <NoHeaderNotification />;
    case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
      return <NoHeaderNotification />;
    default:
      return null;
  }
};

const MainApp = () => {
  const { navStyle } = useSelector(({ settings }) => settings);
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const userCredent = localStorage.getItem("user_credent")
    ? JSON.parse(localStorage.getItem("user_credent"))
    : null;

  const { fetching, payload } = useSelector(({ gate }) => gate);
  const { isConnected } = useSelector(({ checkconnection }) => checkconnection);

  const selectGateByUserRequest = (data) =>
    dispatch(GateActions.selectGateByUserRequest(data));

  useEffect(() => {
    window.addEventListener("resize", () => {
      dispatch(updateWindowWidth(window.innerWidth));
    });
  }, [dispatch]);

  const history = useHistory();

  const [gateId, setGateId] = useState(null);
  const [isAlert, setIsAlert] = useState(false);
  const handleOk = () => {
    if (gateId === null) {
      setIsAlert(true);
      return;
    }
    setIsAlert(false);
    selectGateByUserRequest({ mGateId: gateId, mUserId: userCredent.muserId });
    history.replace("/");
  };

  const onChangeGate = (e) => {
    setGateId(e.target.value);
  };

  const onDoubleClick = () => {
    return handleOk();
  };

  const onChangeConnection = (isConnect) => {
    if (isConnect) {
      message.destroy();
      message.open({
        type: "success",
        content: "Anda kembali online",
        duration: 10,
      });
    } else {
      message.destroy();
      message.open({
        type: "error",
        content: "Anda sedang offline. Periksa koneksi Anda.",
        duration: 10,
      });
    }
    dispatch(CheckConnectionActions.changeStatusConnection(isConnect));
  };

  const { start, stop, isActive } = useInterval(
    () => {
      console.log("Callback every 20000 ms");
      dispatch(SynchronizeActions.syncKeluarKendaraanRequest());
      // dispatch(
      //   SynchronizeActions.autoSynchronizeDataRequest(userCredent.muserId)
      // );
    },
    20000,
    {
      autoStart: false,
      immediate: false,
      selfCorrecting: false,
      onFinish: () => {
        console.log("Callback when timer is stopped");
      },
    }
  );

  useEffect(() => {
    dispatch(CheckConnectionActions.changeStatusConnection(isActive()));
  }, [isActive, dispatch]);

  return (
    <Layout className="gx-app-layout">
      {/* <AppSidebar navStyle={navStyle}/> */}
      {/* <Offline>
        <OfflineResult />
      </Offline> */}
      {/* <Online> */}

      <CheckConnection
        onChangeConnection={onChangeConnection}
        render={({ online }) => <> {online ? start() : stop()}</>}
      />
      <ModalSelectGate
        loading={fetching}
        open={userCredent.selectedGate === null}
        handleOk={handleOk}
        onChange={onChangeGate}
        onDoubleClick={onDoubleClick}
        listGate={payload && payload}
        // listGate={userCredent.gate}
        isAlert={isAlert}
      />
      {userCredent.selectedGate && (
        <Layout>
          {getNavStyles(navStyle)}

          <Content
            className={`gx-layout-content ${getContainerClass(navStyle)} `}
          >
            <App match={match} />
            {/* <Footer>
            <div className="gx-layout-footer-content">{footerText}</div>
          </Footer> */}
          </Content>
        </Layout>
      )}
      {/* </Online> */}
    </Layout>
  );
};
export default MainApp;
