import { call, put } from "redux-saga/effects";
import AuthActions from "../reducers/AuthRedux";

import LoadingOverlayActions from "../reducers/LoadingOverlayRedux";

import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal, Row, Col, message, notification } from "antd";
import getModalAlert from "../../components/ModalAlert";
import TipeKendaraanActions from "../reducers/TipeKendaraanRedux";
import TarifActions from "../reducers/TarifRedux";
import JenisPembayaranActions from "../reducers/JenisPembayaranRedux";
import OrganizationActions from "../reducers/OrganizationRedux";
import GateActions from "../reducers/GateRedux";
import MemberActions from "../reducers/MemberRedux";
import BrandKendaraanActions from "../reducers/BrandKendaraanRedux";
import TenantActions from "../reducers/TenantRedux";
import LocalActions from "../reducers/LocalRedux";
import CheckConnectionActions from "../reducers/CheckConnectionRedux";
import _ from "lodash";
// import { auth } from "../../firebase/firebase";
// import { TemplateSelectors } from '../Redux/TemplateRedux'

// const signInUserWithEmailPasswordRequest = async (email, password) =>
//   await auth
//     .signInWithEmailAndPassword(email, password)
//     .then((authUser) => authUser)
//     .catch((error) => error);

// const signOutRequest = async () =>
//   await auth
//     .signOut()
//     .then((authUser) => authUser)
//     .catch((error) => error);

export function* signinUser(api, action) {
  const { user } = action;
  // console.log("USER TERPASSINFG ???", user);
  // const { email, password } = user;

  // const dummyData = { name: "test", gate: null };
  // localStorage.setItem("user_credent", JSON.stringify(dummyData));

  try {
    const signInUserResponse = yield call(api.authLogin, user);
    // console.log("signInUserResponse", JSON.stringify(signInUserResponse));
    if (signInUserResponse.ok) {
      if (signInUserResponse.data.data.gate.length === 0) {
        getModalAlert("Gate tidak tersedia");
        yield put(AuthActions.showAuthLoader(false));
        return;
      }

      const credent = {
        ...signInUserResponse.data.data,
        selectedGate: null,
        selectedOrganization:
          signInUserResponse.data.data.organization[0].organizationId,
        // gate: null,
        // accessToken: signInUserResponse.headers["x-auth-token"],
      };
      notification.success({
        message: "Sukses",
        description: signInUserResponse.data.message,
      });
      localStorage.setItem("user_credent", JSON.stringify(credent));
      // if (!credent.accessToken) {
      //   Modal.error({
      //     centered: true,
      //     icon: <ExclamationCircleFilled />,
      //     okType: "danger",
      //     content: (
      //       <div>
      //         <p>{"Login Failed, token not found !"}</p>
      //       </div>
      //     ),
      //     title: (
      //       <Row
      //         type="flex"
      //         justify="start"
      //         style={{ alignItems: "center" }}
      //         gutter={[5, 0]}
      //       >
      //         <Col>
      //           <span>Warning !</span>
      //         </Col>
      //       </Row>
      //     ),
      //     onOk() {},
      //     onCancel() {},
      //   });
      //   yield put(AuthActions.showAuthLoader(false));
      // }

      // save data state
      yield put(CheckConnectionActions.changeStatusConnection(true));

      yield put(
        TipeKendaraanActions.saveTipeKendaraan(
          signInUserResponse.data.data.tipeKendaraan
        )
      );
      yield put(
        TarifActions.saveTarif(
          _.orderBy(
            signInUserResponse.data.data.tarif,
            ["tanggalBerlaku"],
            ["desc"]
          )
        )
      );
      yield put(
        JenisPembayaranActions.saveJenisPembayaran(
          signInUserResponse.data.data.jenisPembayaran
        )
      );
      yield put(
        OrganizationActions.saveOrganization(
          signInUserResponse.data.data.organization
        )
      );
      yield put(GateActions.saveGate(signInUserResponse.data.data.gate));
      yield put(MemberActions.saveMember(signInUserResponse.data.data.member));
      yield put(
        BrandKendaraanActions.saveBrandKendaraan(
          signInUserResponse.data.data.brandKendaraan
        )
      );
      yield put(TenantActions.saveTenant(signInUserResponse.data.data.tenant));

      yield put(AuthActions.signinUserSuccess(signInUserResponse.data.message));
    } else {
      //set attempt disini

      // yield put(
      //   AuthActions.setAttempRemaining(
      //     signInUserResponse.data &&
      //       signInUserResponse.data.result &&
      //       signInUserResponse.data.result.remaining_attempt > -1
      //       ? signInUserResponse.data.result.remaining_attempt
      //       : -1
      //   )
      // );
      yield put(AuthActions.showAuthLoader(false));
    }
  } catch (error) {
    yield put(AuthActions.showAuthMessage(error));
  }
}

export function* userSignOut(api, action) {
  const { userid, message } = action;
  yield put(LoadingOverlayActions.showLoadingOverlay(true, "Logging off ..."));
  try {
    const signOutResponse = yield call(api.authLogout, userid);
    // console.log("signOutResponse", JSON.stringify(signOutResponse));

    if (signOutResponse.ok) {
      if (message) {
        Modal.error({
          centered: true,
          icon: <ExclamationCircleFilled />,
          okType: "danger",
          content: (
            <div>
              <p>{message}</p>
            </div>
          ),
          title: (
            <Row
              type="flex"
              justify="start"
              style={{ alignItems: "center" }}
              gutter={[5, 0]}
            >
              <Col>
                <span>Warning !</span>
              </Col>
            </Row>
          ),
          onOk() {},
          onCancel() {},
        });
      }
      localStorage.removeItem("user_credent");
      yield put(AuthActions.userSignOutSuccess(""));
    } else {
      getModalAlert("Logout Gagal");
    }
    // } else {
    //   yield put(AuthActions.showAuthMessage(signOutUser.message));
    // }
  } catch (error) {
    yield put(AuthActions.showAuthMessage(error));
    yield put(LoadingOverlayActions.showLoadingOverlay(false));
  }
  yield put(LoadingOverlayActions.showLoadingOverlay(false));
}

export function* authForgotPasswordRequest(api, action) {
  const { data, cb } = action;
  yield put(LoadingOverlayActions.showLoadingOverlay(true, "Requesting ..."));
  const response = yield call(api.authForgotPasswordRequest, data);
  // console.log("authForgotPasswordRequest response", JSON.stringify(response));
  if (response.ok) {
    if (response.data && !response.data.error) {
      yield put(AuthActions.authForgotPasswordSuccess(response.data));
      if (cb) cb(response.data);
    } else {
      yield put(AuthActions.authForgotPasswordFailure());
    }
  } else {
    yield put(AuthActions.authForgotPasswordFailure());
  }
  yield put(LoadingOverlayActions.showLoadingOverlay(false));
}
